.quiz-container {
    width: 100%;
    max-width: 800px;
    margin: 50px auto; /* Center the quiz container */
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.quiz-container h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: -500px;
    text-align: center;
    color: #333;
}

.quiz-question {
    margin-bottom: 20px;
}

.quiz-question p {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 10px;
    color: #555;
}

.quiz-question label {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 8px;
    padding: 8px;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.quiz-question label:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
}

.quiz-question input[type="radio"] {
    margin-right: 10px;
}

.quiz-section {
    margin-top: 0;
    padding-top: 0;
}

button[type="submit"] {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: green;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button[type="submit"]:hover {
    background-color: darkgreen;
}

@media screen and (max-width: 800px) {
    .quiz-container {
        padding: 1rem;
    }

    .quiz-container h2 {
        font-size: 1.6rem;
    }

    .quiz-question p {
        font-size: 1.1rem;
    }

    button[type="submit"] {
        font-size: 1rem;
    }
}
