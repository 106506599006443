.main-online {
    margin-top: 500px;
    font-size: 32px;
    text-align: center;
}
.photo-carousel-container {
    margin-top: -30px;
}

.course-listing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px; /* Adjust this value as needed */
}

.class-button {
    padding: 10px 70px;
    width: 300px;
    font-size: 1rem;
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;

}

.class-button {
    background-color: #357717;
    color: white;
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.class-description h1 {
    font-size: 42px;
}

.class-description {
    /* margin-top: -700px;
    max-width: 740px;
    margin-left: 670px; */
    /* margin-left: auto;
    margin-right: auto; */
    max-width: 900px;
    text-align: center;
    font-size: 18px; /* You can adjust the font size as needed */
    margin: 30px 0; /* Adjust the left margin as needed */
}

.class-img {
    width: 350px;
    height: 350px;

}

.reg_form {
    text-align:left;
    margin-top: -500px;
    display: none;
}

@media screen and (max-width: 800px) {

    .class-img {
        margin-left: 40px;
    }

    .course-listing {
        margin-top: 50px;
    }

    .course-description {
        margin-left: -40px;
    }
    
    .class-description {
        margin: 40px auto 0; /* Center the description and add top margin */
        max-width: 100%; /* Allow the description to take full width */
        text-align: left; /* Center the text within the description */
        margin-left: 50px;
        font-size: 14px;
      }

    .class-button {
        margin-left: 0px;
    }
}

@media screen and (min-width: 1200px) {
    .main-online {
        margin-top: calc(580px + 27px);
    }
  }