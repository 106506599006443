.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 30px; /* Space between items */
  max-width: 1100px; /* Set a max-width for better centering */
  margin: 0 auto; /* Center the grid */
  padding: 20px; 
}

.grid-cell {
  background-color: #ddd;
  padding: 10px;
  text-align: center;
  /* Ensure cells are responsive */
}

.shifting-all-grids {
  margin-top: 540px;
}

.img-course {
  height: 300px;
  width: 300px;
}

.header-small {
  text-align: center;
  font-size: 24px;
}

p {
  margin-top: 15px;
}

@media screen and (max-width: 800px) {
  .grid-container {
      grid-template-columns: 1fr; /* Single column on mobile */
      width: 90%; /* Width on mobile */
      max-width: none; /* Remove max-width on mobile */
      margin: 0 auto; /* Center on mobile */
  }

  .grid-cell {
      width: 100%; /* Full width on mobile */
  }
}
