.course-img {
    height: 450px;
    width: 450px;
    margin-top: 570px;
    margin-left: 100px;
}

.course-description {
    margin-left: 610px;
    margin-top: -320px;
    max-width: 800px;
}

.course-description h2 {
    margin-bottom: 10px;
  }
  
  .course-description h3 {
    margin-top: 20px;
  }

  .student-l1 h1 {
    margin-left: 10px;
    margin-top: 230px;
    font-size: 50px;
    color: rgb(0, 128, 0);
  }

  .student-l1 h2 {
    margin-left: 164px;
    margin-top: 40px;
    font-size: 34px;
  }

  .dropdown-arrow {
    font-size: 12px;
    margin-left: 5px;
    transition: transform 0.3s ease;
  }

  .expanded {
    transform: rotate(180deg);
  }

  .astrovid {
    margin-left: 380px;
    margin-top: 30px;
  }

  .course-button {
    padding: 10px 20px;
    width: 150px;
    font-size: 1rem;
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
}

.course-button {
    background-color: #357717;
    color: white;
}

.lesson-content {
    display: flex;
    align-items: center;
    margin-left: 7px;
}

.video-container {
    flex: 1;
  }

  .button-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 120px;
  }

  .button-column h3 {
    text-align: center;
    margin-left: -px;
  }

  .astrovid-thumbnail {
    display: block; 
    margin: 0 auto; 
    text-align: center; 
    max-width: 100%; 
    cursor: pointer;
    border: 1px solid #ccc;
    transition: transform 0.2s ease-in-out;
    margin-right: 40px;
    margin-top: 5px;
  }


  .astrovid-thumbnail:hover {
    transform: scale(1.1);
  }

@media screen and (max-width: 800px) {
  .course-description {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 13px;
    margin-top: 10%;
  }

  .course-img {
    margin-left: -10px;
    height: 400px;
    width: 400px;
  }

  .student-l1 {
    margin-left: -140px;
    margin-top: -100px;
  }

  .student-l1 h1 {
    font-size: 20px;
  }

  .lesson1 h2{
    font-size: 20px;
  }

  .lesson2 h2{
    font-size: 20px;
  }

  .lesson3 h2{
    font-size: 20px;
  }

  .lesson4 h2{
    font-size: 20px;
  }

  .astrovid {
    width: 250px;
    height: 200px;
    margin-left: 160px;
  }

  .button-column {
    margin-left: 30px;
    margin-bottom: -15px;
  }

  .course-button {
    padding: 8px 2px 7px 5px;
    width: 70px;
    height: 30px;
    font-size: 8px;
    text-align: center;
    line-height: 1;
  }

  .lesson-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 7px;
  }

  .lesson1 {
    text-align: center;
    margin-bottom: 20px;
    margin-left: 170px;
  }

  .vidtitle-small {
    margin-top: -3px;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: -50px;
  }

  .student-l1 h1 {
    margin-left: 170px;
  }
}
