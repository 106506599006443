
.hero-other {
    background-color: #357717;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 100px;
    z-index: 1;
    height: 40vh; 
}

.hero-title {
    padding-top: 250px;
    padding-left: 50px;
    font-size: 70px;
    color: white;
}

.hero-description {
    padding-left: 50px;
    font-size: 42px;
    color: white;
}

.btns {
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 40vh;
}

.hero-overlay {
    padding-top: 170px;
    padding-left: 585px;
    font-size: 70px;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-weight: bold;
}


/* For extra large screens */
@media screen and (min-height: 1200px) {
    .hero-other {
        height: 35vh;  /* to be changed */
    }
}

/* For medium-large screens */
@media screen and (min-height: 900px) and (max-height: 1199px) {
    .hero-other {
        height: 35vh;  /* to be changed */
    }
}

/* For smaller screens */
@media screen and (max-height: 600px) {
    .hero-other {
        height: 35vh;  /* to be changed */
    }
}