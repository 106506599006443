.header {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
}

.header-bg {
    background-color: white;
    transition: 0.3s;
}

.logo {
    width: 270px;
    height: 75px;
    cursor: pointer;
    margin-top: 10px;
}

.nav-menu { 
    display: flex;
    position: relative;
}

.nav-menu li {
    padding: 0 1rem;
    position: relative;
}

.nav-menu li:hover .dropdown-menu {
    display: block;
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    z-index: 2; 
}

.dropdown-menu li {
    padding: 8px 0;
    margin-top: 4px;
    text-align: center;
}

.dropdown-menu a {
    color: black; 
    text-decoration: none; 
    font-size: 16px;
}

.dropdown-menu a:hover {
    color: green; 
}


.nav-menu a {
    font-size: 1rem;
    font-weight: 500;

}

.hamburger {
    display: none;
}

.login-link {
    border: 2px solid green;
    padding: 8px 16px;
    border-radius: 3px;
}

.login-link:hover {
    border-color: darkgreen;
    background-color: darkgreen;
    color: white;
}

@media screen and (max-width:800px) {
    .hamburger {
        display:block;
    }

    .nav-menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 90px;
        left: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.3s;
        z-index: 1;
        background: rgba(0, 0, 0, 0.9);
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding: 0.8rem 0;
    }

    .nav-menu a {
        font-size: 2rem;
        color: white;
    }


    .nav-menu li:hover .dropdown-menu {
        display: none;
    }

}

