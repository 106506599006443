@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;700&family=Roboto+Condensed:wght@300;400;700&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,p,a {
  color: black;
}
 
body {
  margin: 0;
  font-family: 'Roboto Condensed', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: white;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  padding: 12px 32px;
  font-size: 1.2rem;
  text-transform: uppercase;
  background: transparent;
  color: white;
  border: 1px solid white;
  cursor: pointer;
}

.btn-light {
  background: rgba(255, 255, 255, 0.2);
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2); 
}