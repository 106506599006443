.rectangular-box {
    width: 860px;
    height: 200px;
    background-color: #f0f0f0;
    border: 1px solid #ccc; 
    margin: 20px auto;
    padding: 20px;
}

.boxes-container {
    padding-top: 520px;
}


.box-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
}

.box-button {
    background-color: #357717;
    color: white;
}

.paypal-button {
    margin-right: 13px;
}

@media screen and (max-width: 800px){
    .rectangular-box {
        width: 360px;
        height: auto;
        background-color: #f0f0f0;
        border: 1px solid #ccc; 
        margin: 20px auto;
        padding: 20px;

    }

} 

@media screen and (min-width: 1200px) {
    .boxes-container {
        margin-top: calc(50px + 30px);
    }
  }