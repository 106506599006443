.vidbig {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 570px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1450px;
}

.course-button {
    background-color: #357717;
    color: white;
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 100%;
}

.question-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.question {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.question p {
    margin-bottom: 15px;
    font-size: 18px;
    color: black; 
}

.question input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
}

.question button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #357717;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.question button:hover {
    background-color: #2d6315;
}

.question button:before {
    content: "✔";
    margin-right: 10px;
    font-size: 18px;
}

.expanding-textarea {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    resize: none;
    overflow-y: auto; 
    min-height: 50px;
    max-height: 200px;
    line-height: 1.5;
}

.expanding-textarea:focus {
    outline: none;
    border-color: #357717;
}

.expanding-textarea:focus-visible {
    outline: none;
}

.expanding-textarea::-webkit-scrollbar {
    width: 8px; 
}

.expanding-textarea::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.expanding-textarea::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

@media screen and (max-width: 800px) {
    .vidbig {
        margin-top: 500px;
        margin-left: -80px;
    }

    .centered-container {
        flex-direction: column;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .course-button {
        margin: 5px;
        padding: 8px 16px;
        font-size: 1rem;
        width: 100%;
        max-width: 600px;
    }
}
