.volunteer-info {
    padding: 50px;
    margin-top: 540px;
    text-align: center;
  }
  
  .volunteer-text {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .volunteer-benefits {
    list-style-type: disc;
    margin: 0 auto;
    max-width: 600px;
    text-align: left;
  }
  
  .volunteer-benefits li {
    margin-bottom: 10px;
  }
  
  .apply-button-container {
    margin-top: 30px;
  }
  
  .apply-button {
    background-color: #357717;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .apply-button:hover {
    background-color: #2d6315;
  }
  
  .college-logos-section {
    margin-top: 50px;
    text-align: center;
  }
  
  .college-logos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
  }
  
  .college-logo {
    max-height: 150px;
  }
  
  .college-logos-section h2 {
    margin-bottom: 50px;
    font-size: 40px;
    font-weight: bold;
    color: black;
  }
  