.hello {
    margin-top: 90px;
    width: 82%;
    border: solid #357717 50px;
    background-color: #357717;
}

.hello-text {
    font-size: 70px;
    color: white;
    margin-left: 100px;
}

.grid-container-wrapper {
    margin-top: 75px;
    margin-left: 150px;
    width: calc(100% - 400px);
}

.header-courses {
    text-align: left;
    font-size: 34px;
    margin-bottom: 40px;
}

.img-course {
    height: 200px;
    width: 300px;
    margin-left: -10px;
}

.course-info {
    text-align: center;
    line-height: 10px;
    margin-top: -160px;
    margin-left: 300px;
    max-width: 400px;
}

.header-recommended {
    text-align: left;
    font-size: 34px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.recommended-container {
    width: 95%;
}

.recommended-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
}

.course-card {
    width: 250px;
    flex: 1 1 auto;
    min-width: 250px;
    max-width: calc(20% - 1.6rem);
    background: white;
    border-radius: 8px;
    transition: transform 0.3s ease;
    margin-bottom: 1rem;
}

.course-card:hover {
    transform: translateY(-5px);
}

.course-card-image {
    width: 12rem;
    height: 12rem;
    object-fit: cover;
    display: block;
    margin: 1rem auto;
    border-radius: 50%;
}

.course-card-content {
    padding: 1.5rem;
    text-align: center;
}

.course-card-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
}

.course-card-description {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.5;
}

.dashboard_buttons {
    font-size: 16px;
    display: inline;
    height: 35px;
    color: white;
    border: transparent;
    margin: 20px 50px;
}

.dashboard_buttons:hover {
    cursor: pointer;
}

/* original register button */

/* .recommend-register-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #357717;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.recommend-register-button:hover {
    background-color: #2a5f12;
} */



@media screen and (max-width: 1400px) {
    .course-card {
        max-width: calc(25% - 1.5rem);
    }
    
    .grid-container-wrapper {
        width: calc(100% - 350px);
    }
}

@media screen and (max-width: 1200px) {
    .course-card {
        max-width: calc(33.333% - 1.33rem);
    }
    
    .grid-container-wrapper {
        width: calc(100% - 300px);
    }
}

@media screen and (max-width: 900px) {
    .course-card {
        max-width: calc(50% - 1rem);
    }
    
    .recommended-row {
        gap: 1rem;
    }
}
