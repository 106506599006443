
.upcoming {
    margin-top: -65px;
}


.beaker-img {
    height: auto;
    max-width: 100%;
    width: 400px;
    z-index: 10000;
}

.volunteer {
    text-align: center;
    margin-top: 100px;
}


.upcoming-other {
    margin-left: 210px;
    margin-top: 40px;
}

.beaker-pic {
    height: 600px;
    width: 400px;
}

.class-upcoming {
    height: 300px;
    width: 400px;

}

.hyperlink {
    color: rgb(0, 128, 0);
    text-decoration: underline;
}

h3 {
    margin-top: 20px;
}

.inside {
    text-align: center;
    align-items: center;
    margin-top: 550px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.inside p {
    font-size: 20px;
}

.milestones {
    max-width: 900px;
    text-align: left;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.milestones h1 {
    text-align: center;
}

.milestones p {
    font-size: 20px;
}

.boldings {
    font-weight: 900;
}

@media screen and (max-width: 800px) {
    .class-upcoming {
        display: none;
    }

    .beaker-pic {
        display: none;
    }

    .upcoming-other {
        text-align: center;
        align-items: center;
        margin-left: 5px;
    }

    .upcoming {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: 4px;
        margin-top: 50px;

    }

    
}