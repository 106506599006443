.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    margin-top: 40px;
  }
  
  .login-card {
    padding: 30px 10px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    margin-top: 32%;
  }
  
  .login-form {
    border: 2px solid #ddd;
    border-radius: 8px; 
    padding: 20px; 
  }

  .login-form {
    margin-top: 10px;
  }

  .login-form h1 {
    margin-bottom: 20px;
  }

  .login-form input,
  .login-form button {
    margin-bottom: 10px;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    background-color: darkgreen;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-form button:hover {
    background-color: darkgreen;
  }
  
  .login-form p {
    margin-top: 10px;
    margin-bottom: 0;
    color: black;
  }
  
  .sign-up-link {
    color: darkgreen;
    text-decoration: none;
  }
  
  .sign-up-link:hover {
    text-decoration: underline;
    color: darkgreen;
  }
  