.course-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  margin-top: 500px;
}

.image-column {
  text-align: center;
  margin-bottom: 20px;
}

.description-column {
  text-align: center;
  max-width: 600px;
}

.course-image {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.description-column ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left; 
}

.price {
  font-weight: bold; 
  font-size: 1.2rem; 
}

.original-price {
  text-decoration: line-through;
  color: #ff0000; 
  margin-right: 5px;
}

.sale-price {
  font-weight: bold;
  color: #008000; 
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .course-container {
    margin-top: 500px;
  }

  /* .image-column {
    display: none; 
  } */

  .description-column {
    text-align: center; 
  }
}

@media screen and (min-width: 1200px) {
  .course-container {
      margin-top: calc(500px + 30px);
  }
}
